export const BASE_TRANSITION_LENGTH = 0.6
export const SHORT_TRANSITION_LENGTH = (BASE_TRANSITION_LENGTH * 2) / 3

export function onBackToMain(from, { node }) {
  let main = node.querySelector("main")
  if (main) {
    main.dataset.from = from
  }
}

export function onSetExitTo(to, { node }) {
  let main = node.querySelector("main")
  if (main) {
    main.dataset.to = to
  }
}
