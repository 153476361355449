import React from "react"
import { Link } from "gatsby"
import { useTriggerTransition } from "gatsby-plugin-transition-link"

import "./pageLink.scss"

const PageLink = ({ to, className, exit, entry, children, onPreClick }) => {
  let triggerTransition = useTriggerTransition()

  let onClick = async evt => {
    if (onPreClick) {
      await onPreClick(evt)
    }
    triggerTransition({ to, exit, entry })
  }

  return (
    <>
      <Link to={to} className={`${className} pageLink underlyingLink`}></Link>
      <div className={`${className} pageLink linkOverlay`} onClick={onClick}>
        {children}
      </div>
    </>
  )
}

export default PageLink
